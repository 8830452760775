<template>
	<div class="flex v-end con " ref="item" style="font-size: .14rem">
			<span class="pre-wrap flex-1 two-flow " :class="{'show-all' : myControl}">
			{{ content }}
			</span>
		<u v-if="showExplain" class="color-primary flex-right fs-12" @click="handleExplain"> {{ myControl ? '收缩' : '展开' }}</u>
	</div>

</template>

<script>

import { nextTick, ref } from 'vue'

export default {
	name: 'TheShowExplain',
	props: {
		content: {
			type: String,
			default: ''
		}
	},
	setup() {
		let showExplain = ref(false)
		// 默认展开状态为展开 等页面渲染完成后改成收缩
		let myControl = ref(true)
		// 当视图更新时 判断内容高度是否超过50 超过50证明超过两行 展示收起展开按钮
		// 同时将展开状态全部 默认为收缩
		nextTick(() => {
			// 获取字体大小
			const fontSize = window.getComputedStyle(item.value).fontSize
			// 单行高度 字体 * 行高
			const height = fontSize.slice(0, fontSize.length - 2) * 1.4
			showExplain.value = item.value.clientHeight > ( height  * 2.5)
			myControl.value = false
		})
		// 控制展开收起
		const handleExplain = () => {
			myControl.value = !myControl.value
		}
		const item = ref('')

		return {
			showExplain,
			handleExplain,
			myControl,
			item
		}
	}
}
</script>


<style lang="scss" scoped>
.con {
	margin-bottom: px(15);
}

.show-all {
	-webkit-line-clamp: initial;
}
</style>
